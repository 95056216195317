import { useState, useEffect } from "react";

import amplitude from 'amplitude-js';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import WaitingRegistrationPopup from "../../UI/waitingRegistrationPopup/WaitingRegistrationPopup";
import SuccessRegistrationPopup from "../../UI/successfulRegistrationPopup/SuccessRegistrationPopup";
import styles from "./RegistrationOfferList.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../../UI/lazyImage/LazyImage";
const RegistrationOfferList = props => {
    const { nextStep , liveMode} = props
    const textData = strings.registration.addOffer
    const [displayWaitingRegistrationPopup, setDisplayWaitingRegistrationPopup] = useState(false)
    const [displaySuccessRegistrationPopup, setDisplaySuccessRegistrationPopup] = useState(false)

    const [offerVariant, setOfferVariant] = useState("all");

    const paypalId = 'Af7bctxkCUaREhFJ0BemXCIe_wjt63BQZ6lAPH58TSx-ew4SMvdCAXT5vKgf0iiAPGmGGo9TGSnsRddE'
    const testPaypalId = 'AVMWUzb4KSB_QhCJ6MAB72V7RgeM4KVLPSDcqhDDi6HPfXLB70erhueUmevRgsY74_kXlO4_ucmI75pP'
    const [paypalClientId, setPaypalClientId] = useState( localStorage.getItem('liveMode') === "true" ? paypalId : testPaypalId);
    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_add_offer_appeared');
    }, [])

    const paypalSubscribe = (data, actions) => {
    setDisplayWaitingRegistrationPopup(true)
            //facebookCapi('AddPaymentInfo', `paymentinfo.${getCookie('id')}`, null, period?.paypal_card_id);
            let price = "P-9P505249L00318602M3YWFNY";
                    if(offerVariant === "all") {
                    price = "P-9P505249L00318602M3YWFNY";
                    }
                    if(offerVariant === "pdf") {
                     price = "P-0CA951856W2786439M3YV7WI";
                    }
                    if(offerVariant === "program") {
                       price = "P-78872118MU622581EM27TLGA";
                    }
            return actions.subscription.create({
                plan_id: localStorage.getItem('liveMode') === "true" ? price : "P-01W53846FU6608113M2QQS6A",
            });
        };

        const paypalOnApprove = async (data, details) => {
          setDisplayWaitingRegistrationPopup(false)
                          setDisplaySuccessRegistrationPopup(true)
                          setTimeout(() => {
                           setDisplaySuccessRegistrationPopup(false)
                           nextStep();
          }, 3000);
        }

    const confirmAction = async () => {
         setDisplayWaitingRegistrationPopup(true)
        amplitude.getInstance().logEvent('button_onboarding_add_to_my_plan_tapped');
        ////////
        if(localStorage.getItem('paymentMethodType') === "stripe"){
        let price = "price_1Q2BU5Jwa78Nc1Fj6xSu16sX";
        if(offerVariant === "all") {
        price = "price_1Q2BU5Jwa78Nc1Fj6xSu16sX";
        }
        if(offerVariant === "pdf") {
         price = "price_1Q2BI7Jwa78Nc1FjOlkKaUTd";
        }
        if(offerVariant === "program") {
           price = "price_1PoNzrJwa78Nc1FjiiqN0iGV";
        }
        let purchaseResult = await fetch("https://1fqev002he.execute-api.us-east-1.amazonaws.com/dev/buy_program", {
                   method: "POST",
                   headers: {
                       'Accept': 'application/json',
                       'Content-Type': 'application/json'
                   },
                   body: JSON.stringify({
                       priceId: price,
                       customerId: localStorage.getItem('customer_id'),

                       profileId: localStorage.getItem('profile_id'),
                       programId: offerVariant === 'pdf' ? null : 'ed-1721802472811',
                       specialOffer: offerVariant,
                       paymentMethodId: localStorage.getItem('paymentMethodId'),
                       email: localStorage.getItem('emailInfo'),
                       amplitude_user_id: amplitude.getInstance().options.userId,
                       location: localStorage.getItem('search'),
                       onboarding_id: localStorage.getItem('onboarding_id'),
                       livemode: localStorage.getItem('liveMode'),
                   }),
               }).then(async (response) => {
                   console.log("createSubscription result");
                   let resultBody = response.json();
                   await resultBody.then( async (json) => {
                       console.log(json)

                       return json
                   });
               });
                console.log("purchaseResult");
                console.log(purchaseResult);
                setDisplayWaitingRegistrationPopup(false)
                setDisplaySuccessRegistrationPopup(true)
                setTimeout(() => {
                 setDisplaySuccessRegistrationPopup(false)
                 nextStep();
                }, 3000);

          }

    }

    const skipStep = () => {
        amplitude.getInstance().logEvent('button_onboarding_skip_tapped');
        nextStep();
    }

    return (
    <>
             { displayWaitingRegistrationPopup ? (<WaitingRegistrationPopup />) : null }
             { displaySuccessRegistrationPopup ? (<SuccessRegistrationPopup />) : null }

        <div className={`${styles.mainContainer} flex-column`}>

            <h1 className={styles.title}>{textData.title}</h1>

            <div onClick={() => setOfferVariant("all")} className={offerVariant === 'all' ? styles.offerContainerActive : styles.offerContainerInactive}>
                 <LazyImage className={styles.offerIcon}
                                 src="/images/registration/present_icon.webp"
                                src="/images/registration/present_icon.png"
                                alt=""  />
                <div className={styles.contentContainer}>
                  <p className={`${styles.offerTitle}`} >
                   Healthy Tips + Fat-Burning Plan
                  </p>
                      <div className={styles.discountContainer}>
                           <span className={styles.cardSubtitle}>$20.00</span>
                           <span className={styles.cardSubtitleOnly}>$9.99 only!</span>
                            <div className={styles.ceoDiscount}>75% OFF</div>
                      </div>
               </div>
            </div>


            <div onClick={() => setOfferVariant("pdf")} className={offerVariant === 'pdf' ? styles.offerContainerActive : styles.offerContainerInactive}>
                  <LazyImage className={styles.offerIcon}
                                            src="/images/registration/akilah_icon.webp"
                                            src="/images/registration/akilah_icon.png"
                                            alt=""  />
                  <div className={styles.contentContainer}>
                  <p className={`${styles.offerTitle}`} >
                               Healthy Tips Book form Akilah
                  </p>
                                  <div className={styles.discountContainer}>
                                       <span className={styles.cardSubtitle}>$12.00</span>
                                       <span className={styles.cardSubtitleOnly}>$7.99 only!</span>
                                      <div className={styles.ceoDiscount}>35% OFF</div>
                                  </div>
                  </div>
            </div>


            <div onClick={() => setOfferVariant("program")} className={offerVariant === 'program' ? styles.offerContainerActive : styles.offerContainerInactive}>
                 <LazyImage className={styles.offerIcon}
                                            src="/images/registration/dance_icon.webp"
                                            src="/images/registration/dance_icon.png"
                                            alt=""  />
                 <div className={styles.contentContainer}>
                 <p className={`${styles.offerTitle}`}>
                    Fat-Burning Home Workout Plan
                 </p>
                                  <div className={styles.discountContainer}>
                                       <span className={styles.cardSubtitle}>$12.00</span>
                                       <span className={styles.cardSubtitleOnly}>$7.99 only!</span>
                                       <div className={styles.ceoDiscount}>35% OFF</div>
                                  </div>
                 </div>
            </div>


            <div>
                             <div className={styles.infoContainer}>
                               <LazyImage className={styles.cardIcon}
                                          src="/images/icons/pink_icon_ok.webp" src="/images/icons/pink_icon_ok.png" alt=""  />
                               <p className={`${styles.cardTitle} cardTitle`}>
                                One-time payment
                               </p>
                             </div>
                             {(offerVariant === "pdf" || offerVariant === "all") &&
                             <div className={styles.infoContainer}>
                               <LazyImage className={styles.cardIcon}
                                          src="/images/icons/pink_icon_ok.webp" src="/images/icons/pink_icon_ok.png"
                                                            alt=""  />
                               <p className={`${styles.cardTitle} cardTitle`}>
                                 Unique tips from Akilah to get more value from chair workouts
                               </p>
                             </div>
                             }
                             {(offerVariant === "pdf" || offerVariant === "all") &&
                             <div className={styles.infoContainer}>
                             <LazyImage className={styles.cardIcon}
                                        src="/images/icons/pink_icon_ok.webp" src="/images/icons/pink_icon_ok.png"
                                                              alt=""  />
                               <p className={`${styles.cardTitle} cardTitle`}>
                                  Tips for Plus Size women
                               </p>
                             </div>
                             }
                             {(offerVariant === "program" || offerVariant === "all") &&
                             <div className={styles.infoContainer}>
                              <LazyImage className={styles.cardIcon}
                                         src="/images/icons/pink_icon_ok.webp" src="/images/icons/pink_icon_ok.png"
                                                                                           alt=""  />
                               <p className={`${styles.cardTitle} cardTitle`}>
                                  Special exercises to speed up your
                                  weight loss
                               </p>
                             </div>
                             }
                             {(offerVariant === "program" || offerVariant === "all") &&
                             <div className={styles.infoContainer}>
                              <LazyImage className={styles.cardIcon}
                                         src="/images/icons/pink_icon_ok.webp" src="/images/icons/pink_icon_ok.png"
                                                           alt=""  />
                               <p className={`${styles.cardTitle} cardTitle`}>
                                  Exclusive HIIT and dance workouts from CEO of Everdance app
                               </p>

                             </div>
                             }

            </div>

            {
                        localStorage.getItem('paymentMethodType') === "stripe"
                        ?  <button className={styles.buttonContainer} onClick={confirmAction} >
                                         {textData.continueButton}
                                      </button>
                        :  <PayPalScriptProvider options={{ "client-id": paypalClientId, vault:true }}>
                                             <PayPalButtons
                                                 style={{ layout: "horizontal", width: 185, height: 48, borderRadius: 8 }}
                                                 createSubscription={(data, details) => paypalSubscribe(data, details)}
                                                 onApprove={(data, details) => paypalOnApprove(data, details)}
                                                 onClick={() => amplitude.getInstance().logEvent('button_onboarding_payment_paypal_tapped')}
                                             />
                                         </PayPalScriptProvider>
            }


{/*             <div className={`${styles.ceoBox} flex-column`}> */}
{/*                 <div className={styles.ceoImage}> */}
{/*                     <LazyImage */}
{/*                         className={styles.image} */}
{/*                         srcWebp="/images/result/registration-offer.webp" */}
{/*                         src="/images/result/registration-offer.png" */}
{/*                         alt="" */}
{/*                     /> */}
{/*                 </div> */}
{/*                 <div className={`${styles.ceoBoxInfo} flex-column`}> */}
{/*                     <div className={styles.ceoTitle}> */}
{/*                         {textData.ceoTitle} */}
{/*                     </div> */}
{/*                     <div className={styles.ceoSubtitle}> */}
{/*                         {textData.ceoSubtitle} */}
{/*                     </div> */}
{/*                     <div className={`${styles.ceoBonus} flex-row`}> */}
{/*                         <div className={`${styles.ceoBonusColumn} flex-row`}> */}
{/*                             <div className={styles.ceoBonusImage}> */}
{/*                                 <LazyImage */}
{/*                                     className={styles.image} */}
{/*                                     srcWebp="/images/icons/fire-3x.webp" */}
{/*                                     src="/images/icons/fire-3x.png" */}
{/*                                     alt="" */}
{/*                                 /> */}
{/*                             </div> */}
{/*                             <div className={styles.ceoBonusInfo}> */}
{/*                                 {textData.ceoWorkouts} */}
{/*                             </div> */}
{/*                         </div> */}
{/*                         <div className={`${styles.ceoBonusColumn} flex-row`}> */}
{/*                             <div className={styles.ceoBonusImage}> */}
{/*                                 <LazyImage */}
{/*                                     className={styles.image} */}
{/*                                     srcWebp="/images/gift_icon.webp" */}
{/*                                     src="/images/gift_icon.png" */}
{/*                                     alt="" */}
{/*                                 /> */}
{/*                             </div> */}
{/*                             <div className={styles.ceoBonusInfo}> */}
{/*                                 {textData.ceoWarmups} */}
{/*                             </div> */}
{/*                         </div> */}
{/*                     </div> */}
{/*                     <div className={`${styles.ceoPrice} flex-between`}> */}
{/*                         <div className={`${styles.ceoPriceContainer} flex-column`}> */}
{/*                             <h3 className={styles.ceoPriceTitle}>{textData.ceoAccess}</h3> */}
{/*                             <div className={`flex-row`}> */}
{/*                                 <p className={styles.oldPrice}>$31.99 <span></span></p> */}
{/*                                 <div className={styles.ceoDiscount}>{textData.ceoDiscount}</div> */}
{/*                             </div> */}
{/*                         </div> */}

{/*                     </div> */}
{/*                 </div> */}
{/*             </div> */}
{/*             { */}
{/*             localStorage.getItem('paymentMethodType') === "stripe" */}
{/*             ?  <button className={styles.buttonContainer} onClick={confirmAction} > */}
{/*                              {textData.continueButton} */}
{/*                           </button> */}
{/*             :  <PayPalScriptProvider options={{ "client-id": paypalClientId, vault:true }}> */}
{/*                                  <PayPalButtons */}
{/*                                      style={{ layout: "horizontal", width: 185, height: 48, borderRadius: 8 }} */}
{/*                                      createSubscription={(data, details) => paypalSubscribe(data, details)} */}
{/*                                      onApprove={(data, details) => paypalOnApprove(data, details)} */}
{/*                                      onClick={() => amplitude.getInstance().logEvent('button_onboarding_payment_paypal_tapped')} */}
{/*                                  /> */}
{/*                              </PayPalScriptProvider> */}
{/*             } */}

{/*             <div className={`${styles.skipButton} flex-row`} onClick={skipStep}> */}
{/*                 <span className={styles.buttonText}>{textData.skipButton}</span> */}
{/*                 <img className={styles.skipIcon} loading="lazy" src="/images/icons/gray-arrow.svg" alt="" /> */}
{/*             </div> */}
        </div>
     </>
    )
}

export default RegistrationOfferList;