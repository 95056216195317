import {useEffect} from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import ResultHeader from "../../UI/resultHeader/ResultHeader";
import styles from "./AboutResult.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../../UI/lazyImage/LazyImage";
import VideoFeedbacks from "../../UI/videoFeedbacks/VideoFeedbacks";

const AboutResult = props => {
    const { nextStep, data } = props;
    const ageInfo = localStorage.getItem('ageInfo');
    const targetWeight = localStorage.getItem('targetWeight');
    const pageInfo = data.settings.aboutResult
    const themeData = data.themeData

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_section_about_you_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_section_about_you_tapped');
        nextStep();
    }

    const imageClass = pageInfo.smallImage ? `${styles.image} ${styles.smallImage}` : `${styles.image}`

    return (
        <>
            { pageInfo.withDecoration && (data.campaign === 'ny' ? (
                <>
                    <LazyImage
                        className={`${styles.absoluteBackground} ${styles.absoluteBackgroundLeft}`}
                        srcWebp="/images/result/about-result-background-left-ny.webp"
                        src="/images/result/about-result-background-left-ny.png"
                        alt=""
                    />
                    <LazyImage
                        className={`${styles.absoluteBackground} ${styles.absoluteBackgroundRight}`}
                        srcWebp="/images/result/about-result-background-right-ny.webp"
                        src="/images/result/about-result-background-right-ny.png"
                        alt=""
                    />
                    <LazyImage
                        className={styles.absoluteImageNy}
                        srcWebp={pageInfo.decoration_imageWebp}
                        src={pageInfo.decoration_image}
                        alt=""
                    />
                </>
                ) : ( pageInfo.decoration_image &&
                    <LazyImage
                        className={styles.absoluteImage}
                        srcWebp={strings.imgs[`${pageInfo.imageLocalID}Webp`]}
                        src={strings.imgs[pageInfo.imageLocalID]}
                        alt=""
                    />
                )
            )}
            <div className={`${styles.mainContainer} flex-column`}>
                <ResultHeader
                    url={'/images/result/about-result.jpg'}
                    urlWebp={'/images/result/about-result.webp'}
                    theme={themeData?.menuProgressBackground}
                />
                { pageInfo.withWeightInfo ? (
                    <div className={`${styles.bVariantResult} flex-column`}>
                        { pageInfo.video
                       ? <VideoFeedbacks feedbacks={pageInfo.feedbacks} title={false} name={false} view="circle"/>
                       : <LazyImage
                            className={imageClass}
                            srcWebp={pageInfo.imageWebp}
                            src={pageInfo.image}
                            alt=""
                        />}
                        <h1>{strings.about.result.title}{ageInfo}</h1>
                        { pageInfo.withMultiColorInfo ?
                            (
                                <div className={`${styles.multiColorText} flex-column`}>
                                    <p className={styles.text}>
                                        {strings.about.result.subtitleColor.first}
                                    </p>
                                    <p className={styles.text}>
                                        {strings.about.result.subtitleColor.second}
                                    </p>
                                    <p className={`${styles.text} flex-row`}>
                                        {strings.about.result.subtitleColor.start}
                                        <span className={styles.red}>{strings.about.result.subtitleColor.red}</span>
                                        {strings.about.result.subtitleColor.and}
                                        <span className={styles.blue}>{strings.about.result.subtitleColor.blue}</span>
                                        {strings.about.result.subtitleColor.end}
                                    </p>
                                    <p className={styles.text}>
                                        {strings.about.result.subtitleColor.last}
                                    </p>
                                    <p className={styles.boldText}>
                                        {strings.about.result.subtitleColor.bold}
                                    </p>
                                </div>
                            ) : (
                                <p className={`${styles.text} resultSubtitle`}>
                                    {strings.about.result.subtitleStart}<b>{targetWeight}</b>,{strings.about.result.subtitleEnd}
                                </p>
                            )
                        }
                    </div>
                    ) : (
                        <>
                            <h1>We Got You!</h1>
                            <span className={`${styles.subtitle} componentSubtitle`}>
                                <span className={styles.percentage}>88,6%</span> of users report positive changes
                                <b>after 1-week</b> of use.
                            </span>
                            <LazyImage
                                className={styles.image}
                                srcWebp="/images/result/about-image.webp"
                                src="/images/result/about-image.jpg"
                                alt=""
                            />
                            <p className={`${styles.text} resultSubtitle`}>Let's learn more about your lifestyle</p>
                        </>
                    )
                }
                <div className="buttonContainer">
                    <ContinueButton nextStep={clickContinue} theme={themeData.continueButton} />
                </div>
            </div>
        </>
    )
}   

export default AboutResult;